<template>
  <el-row style="width: 250px">
    <el-col :class="$langConfig.cssClass" style="width: 100%">
      <dg-text-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="9"
          :label_span="15"
          :translateLabel='false'
          :field_mark="fieldMark"
          label="Ontario Company Key"
          placeholder="xxxxxxxxx"
          maxLength="9"
          name="business_ontario_company_key"
          :rules="rules"></dg-text-field>
    </el-col>

    <!--<el-col :class="$langConfig.cssClass" style="width: 100%">
      <dg-text-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="23"
          :label_span="24"
          :translateLabel='false'
          :field_mark="fieldMark"
          label="Ontario Company Key"
          placeholder="123-456-789"
          maxLength="11"
          :liveUpdate="true"
          name="business_ontario_company_key"
          @amUpdated="part1Updated"
          :rules="rules"></dg-text-field>
    </el-col>-->
  </el-row>
</template>

<script>
export default {
  methods: {
    mask (value) {
      let output = [];
      for(let i = 0; i < value.length; i++) {
        if(i !== 0 && i % 3 === 0) {
          output.push("-"); // add the separator
        }
        output.push(value[i]);
      }
      return output.join("")
    },
    unmask (val) {
      return val.replace(new RegExp(/[^\d]/, 'g'), ''); // Remove every non-digit character
    },
    part1Updated (props) {
      let value = props.field.field_value
      value = this.unmask(value)
      props.field.field_value =  this.mask(value);
    }
  },
  props: {
    fieldMark: {
      default: 'optional'
    },rules: {
      default: ''
    },
    control: {
      required :true
    },
    editMode: {
      required :true
    }
  }
}
</script>
